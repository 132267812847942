import "./index.css";

const Skill = ({ src, name }) => {
  return (
    <div data-tooltip={name} style={{ width: "117px", display: "inline" }}>
      <img
        src={src}
        alt={`${name} logo`}
        width="85"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      />
    </div>
  );
};

export default Skill;
