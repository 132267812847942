import Skill from "./skill";
import back2 from "../../../img/5.jpg";

//main skills
import reactLogo from "../../../img/react.png";
import ts from "../../../img/ts.png";
import reduxLogo from "../../../img/reduxlogo.png";
import js from "../../../img/js.png";
import html from "../../../img/html.png";
import css from "../../../img/css.png";
import mongo from "../../../img/mongo.png";
import rest from "../../../img/rest.png";
import node from "../../../img/node.png";
import rabbit from "../../../img/rabbit.png";
import express from "../../../img/express.png";
import docker from "../../../img/docker.png";
import microservices from "../../../img/microservices.png";
import next from "../../../img/next.png";
import nest from "../../../img/nest.jpeg";

// additional skills
import python from "../../../img/python.jpeg";
import tdd from "../../../img/tdd.png";
import webpack from "../../../img/webpack.png";
import vite from "../../../img/vite.jpeg";
import ansible from "../../../img/ansible.png";
import mocha from "../../../img/mocha.png";
import bamboo from "../../../img/bamboo.png";
import jenkins from "../../../img/jenkins.png";
import grafana from "../../../img/grafana.png";
import chai from "../../../img/chai.png";
import sinon from "../../../img/sinon.png";
import git from "../../../img/git.png";
import graphql from "../../../img/graphql.png";
import apollo from "../../../img/apollo.png";
import ph from "../../../img/ph.png";
import kibana from "../../../img/kibana.png";
import prometheus from "../../../img/prometheus.png";
import bitbucket from "../../../img/bitbucket.png";

//languages
import eng from "../../../img/eng.png";
import spain from "../../../img/spain.png";
import ger from "../../../img/germany.png";
import rus from "../../../img/russia.png";

import "./index.css";

const Skills = () => {
  return (
    <>
      <img className="back" src={back2} alt="back2" />
      <div className="content skills">
        <div className="bot">
          <div className="line">
            <h3>Main skills:</h3>
            <div className="tech">
              <Skill src={reactLogo} name="React" />
              <Skill src={ts} name="TypeScript" />
              <Skill src={reduxLogo} name="Redux" />
              <Skill src={js} name="JavaScript" />
              <Skill src={html} name="HTML" />
              <Skill src={css} name="CSS" />
              <Skill src={mongo} name="MongoDB" />
              <Skill src={node} name="NodeJS" />
              <Skill src={rest} name="Rest API" />
              <Skill src={express} name="Express" />
              <Skill src={next} name="NextJS" />
              <Skill src={nest} name="NestJS" />
              <Skill src={rabbit} name="RabbitMQ" />
              <Skill src={docker} name="Docker" />
              <Skill src={microservices} name="Microservices" />
            </div>
            <h3>Additional skills:</h3>
            <div className="tech">
              <Skill src={python} name="Python" />
              <Skill src={tdd} name="TDD" />
              <Skill src={graphql} name="GraphQL" />
              <Skill src={apollo} name="Apollo" />
              <Skill src={git} name="Git" />
              <Skill src={ph} name="Photoshop" />
              <Skill src={webpack} name="Webpack" />
              <Skill src={vite} name="Vite" />
              <Skill src={ansible} name="Ansible" />
              <Skill src={bamboo} name="Bamboo" />
              <Skill src={jenkins} name="Jenkins" />
              <Skill src={grafana} name="Grafana" />
              <Skill src={kibana} name="Kibana" />
              <Skill src={prometheus} name="Prometheus" />
              <Skill src={mocha} name="Mocha" />
              <Skill src={chai} name="Chai" />
              <Skill src={sinon} name="Sinon" />
              <Skill src={bitbucket} name="Bitbucket" />
            </div>
          </div>

          <div className="line">
            <h3 style={{ marginTop: "10px" }}>
              Ranked in Top-300 on{" "}
              <a
                href="https://www.codewars.com/users/leaderboard"
                target="_blank"
                rel="noopener noreferrer"
              >
                codewars.com with JavaScript
              </a>
            </h3>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.codewars.com/users/Konstantin%20Modin"
              >
                <img
                  className="cw"
                  src="https://www.codewars.com/users/Konstantin%20Modin/badges/large"
                  alt="codewars badge large"
                  // className="lowTransform"
                />
              </a>
            </div>
          </div>

          <div className="line">
            <div className="tech">
              <h3 style={{ marginTop: "10px" }}>Stack Overflow Links:</h3>
              <a
                href="https://stackoverflow.com/users/11124305/konstantin-modin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://stackoverflow.com/users/flair/11124305.png?theme=dark"
                  width="208"
                  height="58"
                  alt="profile for Konstantin Modin at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                  title="Profile for Konstantin Modin at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                  style={{ margin: "5px" }}
                  className="lowTransform stack"
                />
              </a>
              <a
                href="https://ru.stackoverflow.com/users/376593/konstantin-modin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://ru.stackoverflow.com/users/flair/376593.png?theme=dark"
                  width="208"
                  height="58"
                  alt="Профиль участника Konstantin Modin на сайте &#171;Stack Overflow на русском&#187;, Вопросы и ответы для программистов"
                  title="Профиль участника Konstantin Modin на сайте &#171;Stack Overflow на русском&#187;, Вопросы и ответы для программистов"
                  style={{ margin: "5px" }}
                  className="lowTransform stack"
                />
              </a>
            </div>
          </div>

          <div className="line">
            <h3 style={{ marginTop: "20px" }}>Languages</h3>
            <div className="lang">
              <div className="line">
                <img src={eng} alt="eng logo" width="85" />
                <h6>
                  Full <br />
                  Professional
                </h6>
              </div>
              <div className="line">
                <img src={spain} alt="spain logo" width="85" />
                <h6>
                  Full <br />
                  Professional
                </h6>
              </div>
              <div className="line">
                <img src={ger} alt="ger logo" width="85" />
                <h6>Elementary</h6>
              </div>
              <div className="line">
                <img src={rus} alt="rus logo" width="85" />
                <h6>Native</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
