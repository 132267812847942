import React from "react";

import back3 from "../../../img/9.jpg";

import proj1 from "../../../img/proj1.jpg";
import proj2 from "../../../img/proj2.jpg";
import proj3 from "../../../img/proj3.jpg";
import proj4 from "../../../img/proj4.jpg";
import proj5 from "../../../img/proj5.jpg";
import proj6 from "../../../img/proj6.jpg";

const Projects = () => {
  return (
    <>
      <img className="back" src={back3} alt="back3" />
      <div className="content">
        <div className="top hide">
          <h3>Projects:</h3>
        </div>
        <div className="bot project">
          <a
            href="https://suspicious-villani-0c6e2d.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="row">
              <img src={proj3} alt="project3 logo" />
              <div>
                <h3>Minesweeper</h3>
                <h6>
                  Classical minesweeper written with React with option to set
                  field size and density of mines
                </h6>
              </div>
            </div>
          </a>
          <a
            href="https://konstantinmodin.github.io/fifteen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="row">
              <img src={proj4} alt="project4 logo" />
              <div>
                <h3>Fifteen Game</h3>
                <h6>
                  Simple implementation of fifteen game, with posibility to
                  change field size
                </h6>
              </div>
            </div>
          </a>
          <a
            href="https://modina.us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="row">
              <img src={proj5} alt="project5 logo" />
              <div>
                <h3>Psychologist website</h3>
                <h6>Psychologist landing page</h6>
              </div>
            </div>
          </a>
          <a
            href="https://www.spain-alicante.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="row">
              <img src={proj1} alt="project1 logo" />
              <div>
                <h3>Real Estate Agency Website</h3>
                <h6>
                  Real Estate for sell, for rent, excursions information, etc.
                </h6>
              </div>
            </div>
          </a>
          <a
            href="https://lucid-volhard-4ac45f.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="row">
              <img src={proj2} alt="project2 logo" />
              <div>
                <h3>React experiments</h3>
                <h6>
                  Some home projects with React/Redux including data fetching,
                  routing, lazy loading, etc. Available on my Github page
                </h6>
              </div>
            </div>
          </a>
          <a
            href="https://konstantinmodin.github.io/react-black-jack/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="row">
              <img src={proj6} alt="project6 logo" />
              <div>
                <h3>Black-Jack</h3>
                <h6>
                  Simple implementation of Black-Jack game, written with React
                </h6>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Projects;
